<template>
  <section id="ai-trainer">
    <Filters
      :filter-model="filterModel"
      @init="filterChange"
      @on-apply="onApplyFilters"
    />
    <QuestionsTable
      :interval="interval"
      :services="services"
      :platforms="platforms"
      :trainerStatus="trainerStatus"
    />
  </section>
</template>

<script>
import { mapState } from 'vuex'
import { ALL_CHATS_PLATFORMS } from '@/constants/constants'

export default {
  name: 'AITrainer',
  data() {
    return {
      title: '',
      filterModel: [],
      services: [],
      interval: {},
      platforms: [],
      trainerStatus: null,
      intent: null
    }
  },
  computed: {
    ...mapState(['lang', 'languageSelected'])
  },
  components: {
    Filters: () => import('../../../metrics/filters/Filters.vue'),
    QuestionsTable: () =>
      import('../../../metrics/charts/questions_table/QuestionsTable.vue')
  },
  methods: {
    parseRange(values) {
      const from = values[0]
      const to = values[1]
      return { from, to }
    },
    onApplyFilters(filters) {
      const filtersTypes = Object.keys(filters)
      filtersTypes.forEach(filter => this.filterChange(filters[filter]))
    },
    filterChange(filter) {
      switch (filter.type) {
        case 'botNoVersion':
          this.services = filter.value
          break
        case 'date':
          if (filter.value[0] && filter.value[1]) {
            this.interval = this.parseRange(filter.value)
          }
          break
        case 'platform':
          this.platforms = filter.value //.map(platform => platform)
          break
        case 'trainerStatus':
          this.trainerStatus =
            filter.value && filter.value.status ? filter.value.status : null
          break
        default:
          break
      }
    },
    weekInterval() {
      const to = new Date()
      let from = new Date(to.getTime())
      from.setDate(from.getDate() - 7)
      return {
        from: new Date(from.setHours(0, 0, 0, 0)),
        to: new Date(to.setHours(23, 59, 59, 999))
      }
    },
    setFilterModel() {
      this.filterModel = [
        {
          name: 'dates',
          component: 'DateRangeFilter',
          width: 3,
          props: {
            value: this.interval
          }
        },
        {
          name: 'botsNoVersion',
          component: 'BotNoVersionFilter',
          width: 3,
          props: {
            multipleSelect: true,
            allowEmpty: true
          }
        },
        {
          name: 'platform',
          component: 'PlatformFilter',
          width: 3,
          props: {
            multipleSelect: true,
            options: ALL_CHATS_PLATFORMS,
            startAll: true
          }
        },
        {
          name: 'trainerStatus',
          component: 'TrainerStatusFilter',
          width: 3
        }
      ]
    }
  },
  async mounted() {
    this.interval = this.weekInterval()
    this.setFilterModel()
  }
}
</script>
